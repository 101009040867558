import { Component, OnInit } from "@angular/core";
import {
  FormGroupName,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { AdminService } from "app/services/admin.service";
import { NGXToastrService } from "app/services/toastr.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { RouteDataService } from "app/services/route-data.service";

@Component({
  selector: "app-create-person-invitation",
  templateUrl: "./create-person-invitation.component.html",
  styleUrls: ["./create-person-invitation.component.scss"]
})
export class CreatePersonInvitationComponent implements OnInit {
  current_user: any;

  invitationForm: FormGroup;

  invitation_to_create: any = null;

  constructor(
    public activeModal: NgbActiveModal,
    private route_data_service: RouteDataService,
    private admin_service: AdminService,
    private formBuilder: FormBuilder,
    private toastr: NGXToastrService
  ) {}

  ngOnInit() {
    this.current_user = this.route_data_service.tempData;
    this.invitationForm = this.formBuilder.group({
      user: this.current_user.usuario,
      id_musicartes: new FormControl(
        this.invitation_to_create ? this.invitation_to_create.codigo : ""
      ),
      full_name: new FormControl(
        this.invitation_to_create ? this.invitation_to_create.nombre : "",
        [Validators.required]
      ),
      email: [
        "",
        [
          Validators.required,
          Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$")
        ]
      ],
      profile: new FormControl("", Validators.required)
    });
  }

  SendInvitation() {
    if (this.invitationForm.valid) {
      if (this.invitationForm.value.id_musicartes != "") {
        let data_to_save = {
          user: this.invitationForm.value.user,
          info: this.invitationForm.value
        };
        this.admin_service
          .createIndependentInvitation(data_to_save)
          .subscribe((data: any) => {
            if (
              data &&
              data.resultado &&
              data.resultado.header &&
              data.resultado.header.type == "success"
            ) {
              this.toastr.fieldInvitationSuccess();
              this.activeModal.close("Ok");
            } else {
              this.toastr.typeError();
            }
          });
      } else {
        this.admin_service
          .sendInvitation(this.invitationForm.value)
          .subscribe((data: any) => {
            console.log("regreso: " + JSON.stringify(data));
            if (data && data.resultado && data.resultado.header) {
              if (data.resultado.header.type == "success") {
                this.toastr.fieldInvitationSuccess();
                this.activeModal.close("Ok");
              } else {
                this.toastr.toast_error(data.resultado.header.msg);
              }
            } else {
              this.toastr.toast_error("");
            }
          });
      }
    }
  }
}
