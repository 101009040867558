import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbActiveModal, NgbDatepickerConfig, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { PublicService } from 'app/services/public.service';
import { PersonService } from 'app/services/person.service';
import { AppointmentService } from 'app/services/appointment.service';

import * as moment from 'moment';

@Component({
    selector: 'app-create-appoinment',
    templateUrl: './create-appoinment.component.html',
    styleUrls: ['./create-appoinment.component.scss']
})
export class CreateAppoinmentComponent implements OnInit {

    user_data: any;
    limit_date: Date = new Date();
    today: Date = new Date();
    specificUser: boolean = false;

    formAppointment: FormGroup;
    constructor(
        public activeModal: NgbActiveModal,
        datepicker_config: NgbDatepickerConfig,
        calendar: NgbCalendar,
        private formBuilder: FormBuilder,
        private person_service: PersonService,
        private public_service: PublicService,
        private appointment_service: AppointmentService) {

        this.limit_date.setMonth(this.today.getMonth() + 1);
        datepicker_config.minDate = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() };
        datepicker_config.maxDate = { year: this.limit_date.getFullYear(), month: this.limit_date.getMonth() + 1, day: this.limit_date.getDate() };
        datepicker_config.outsideDays = 'hidden';
        datepicker_config.markDisabled = (date: NgbDate) => calendar.getWeekday(date) >= 6;

    }

    ngOnInit() {
        this.person_service.getSessionPerson().subscribe((data: any) => {
            console.log(data);
            this.user_data = data;
        });
        this.formAppointment = this.formBuilder.group({
            //fullname: new FormControl('', [Validators.required]),
            //phone_number: new FormControl('', ),
            email: new FormControl(),
            date: new FormControl('', [Validators.required]),
            time: new FormControl('', [Validators.required]),
            subject: new FormControl('', [Validators.required]),
            comment: new FormControl(),
            recipients: new FormControl(),
            seen_by: []
            //-tipo de evento
        });
    }
    schedule(): void {
        console.log();
        if (this.formAppointment.valid) {
            //console.log(this.formAppointment.value);
            let appoinment = this.formAppointment.value;
            appoinment.creator = this.user_data.usuario;
            appoinment.creation_date = moment().format('L');
            appoinment.creation_time = moment().format('LT');
            appoinment.state = "a";

            console.log("Evento: ", appoinment);

            if (appoinment.recipients.includes('specific')) {
                console.log("send email");
                this.appointment_service.sendAppoinmentEmail(appoinment).subscribe((data: any) => {
                    console.log(data);
                });
            }
            this.appointment_service.createAppointment(this.formAppointment.value).subscribe((data: any) => {
                console.log(data);
                this.formAppointment.reset();
                this.activeModal.close('Ok');
            });


        }
    }
    userRecipientsChange(): void {
        console.log(this.formAppointment.value);

        if (this.formAppointment.value.recipients.length === 1 && this.formAppointment.value.recipients[0] === "specific") {
            this.specificUser = true;
        } else {
            this.specificUser = false;
        }
    }
}
