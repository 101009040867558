import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

@Injectable()
export class NGXToastrService {
    constructor(public toastr: ToastrService) { }

    toast_sucess(text: string) {
        this.toastr.success(text);
    }
    toast_error(text: string) {
        this.toastr.error(text);
    }
    toast_warning(text: string) {
        this.toastr.warning(text);
    }

    typeSuccess() {
        this.toastr.success('Petición realizada con exito.');
    }
    typeError() {
        this.toastr.error("Ha ocurrido un error realizando esta petición.");
    }

    typeErrorText(text: string) {
        this.toastr.error("Ha ocurrido un error realizando esta petición.\n " + text);
    }

    UploadSuccess() {
        this.toastr.success('Archivo subido con exito.');
    }

    fieldUpdatedSuccess() {
        this.toastr.success('¡Se han actualizado los campos con éxito!');
    }
    fieldUpdatedError() {
        this.toastr.error("Ha ocurrido un error guardando tu información.");
    }
    fieldInvitationSuccess() {
        this.toastr.success("Socio invitado con exito.");
    }

    defaultSuccess() {
        this.toastr.success("Petición realizada con éxito.");
    }
    modifyPasswordSuccess() {
        this.toastr.success("Contraseña modificada con éxito.");
    }
    dataSavedSuccess() {
        this.toastr.success("Registro creado exitosamente");
    }
    userHasNoAgreements() {
        this.toastr.warning("El usuario no cuenta con convenios asignados.");
    }


    appointmentCreated() {
        this.toastr.success("Evento Creado exitosamente.");
    }
    appointmentConfirmationSent() {
        this.toastr.success("Confirmación enviada exitosamente.");
    }

    
}