<div class="modal-header">
  <h4 class="modal-title text-center">Invitar Socio</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('x')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- <p>{{invitation_to_create | json}}</p>
  <p>{{invitationForm.value | json}}</p> -->
  <form class="form" [formGroup]="invitationForm">
    <div class="form-body">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Nombre</label>
            <input type="text" name="" id="" class="form-control" formControlName="full_name">
            <small class="form-text text-muted danger"
              *ngIf="!invitationForm.get('full_name').valid && (invitationForm.get('full_name').dirty|| invitationForm.get('full_name').touched)">¡Este
              campo es requerido!</small>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Correo</label>
            <input type="email" name="" id="" class="form-control" formControlName="email">
            <small class="form-text text-muted danger"
              *ngIf="!invitationForm.get('email').valid && (invitationForm.get('email').dirty|| invitationForm.get('email').touched)">¡Este
              campo es requerido!</small>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Tipo</label>
            <select class="form-control" name="type" id="type" formControlName="profile">
              <option value="interpreter" selected>Interprete</option>
              <option value="performer" selected>Ejecutante</option>
              <option value="manager" selected>Manager</option>
              <option value="publisher" selected>Publisher</option>
              <option value="beneficiary" selected>Beneficiario</option>
              <!-- <option value="admin" selected>Admistrador</option>
                        <option value="president" selected>Presidencia</option>
                        <option value="monitor" selected>Monitor</option>
                        <option value="assistant" selected>Interprete</option>-->
            </select>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-raised btn-raised btn-primary" (click)="SendInvitation()">
    Invitar Socio
  </button>
</div>