<div class="modal-header">
  <h4 class="modal-title text-center">Registro de Artista</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('x')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="beneficiaryForm">
    <div class="row">
      <div class="col-12 col-md-3 col-lg-3">
        <div class="form-group ">
          <label for="eventType2 ">Parentesco</label>
          <select class="custom-select form-control" data-placeholder="" formControlName="relationship">
            <option value="padre/madre">Padre/Madre</option>
            <option value="hijo/a">Hijo(a)</option>
            <option value="conyuge">Cónyuge</option>
            <option value="abuelo/a">Abuelo(a)</option>
            <option value="nieto/a">Nieto(a)</option>
            <option value="hermano/a">Hermano(a)</option>
            <option value="otro">Otro</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group ">
          <label for=" ">Porcentaje</label>
          <input type="number" class="form-control" formControlName="percentage">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group ">
          <label for=" ">Primer Nombre</label>
          <input type="text " class="form-control" formControlName="first_name">
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group ">
          <label for=" ">Segundo Nombre</label>
          <input type="text " class="form-control" formControlName="middle_name">
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group ">
          <label for=" ">Primer Apellido</label>
          <input type="text " class="form-control" formControlName="last_name">
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group ">
          <label for=" ">Segundo Apellido</label>
          <input type="text " class="form-control" formControlName="second_last_name">
        </div>
      </div>
    </div>
    <div class="row ">
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group ">
          <label>Fecha de Nacimiento</label>
          <!-- <input type="date" class="form-control" formControlName="birthday" placeholder="yyyy-mm-dd"
                      required> -->
          <div class="input-group">
            <input class="form-control" placeholder="yyyy-mm-dd" ngbDatepicker #d="ngbDatepicker"
              formControlName="birthday">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                <i-feather name="calendar"></i-feather>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group ">
          <label for="radios ">Género</label>
          <div class="custom-control custom-radio display-block ">
            <input type="radio" id="radioGenderBeneficiary1" class="custom-control-input" value="Masculino"
              formControlName="gender">
            <label class="custom-control-label" for="radioGenderBeneficiary1">Masculino</label>
          </div>
          <div class="custom-control custom-radio display-block ">
            <input type="radio" id="radioGenderBeneficiary2" class="custom-control-input" value="Femenino"
              formControlName="gender">
            <label class="custom-control-label" for="radioGenderBeneficiary2">Femenino</label>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group ">
          <label for="firstName2">Nacionalidad</label>
          <input type="text" class="form-control" formControlName="birth_place">
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group ">
          <label for=" ">CUI/DPI/PASAPORTE</label>
          <input type="text" class="form-control" formControlName="identifier">
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-raised btn-raised btn-primary" (click)="saveBeneficiary()"
    *ngIf="beneficiaryForm.valid">
    Añadir Beneficiario
  </button>
</div>