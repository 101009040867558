import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'app/services/admin.service';
import { RouteDataService } from 'app/services/route-data.service';
import { NGXToastrService } from 'app/services/toastr.service';

@Component({
    selector: 'app-create-admin-user',
    templateUrl: './create-admin-user.component.html',
    styleUrls: ['./create-admin-user.component.scss']
})
export class CreateAdminUserComponent implements OnInit {

    current_user: any;

    user: any = '';

    createAdminUserForm: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        public activeModal: NgbActiveModal,
        private admin_service: AdminService,
        private route_data_service: RouteDataService,
        private toastr: NGXToastrService
    ) { }

    ngOnInit() {
        this.current_user = this.route_data_service.tempData;
        this.createAdminUserForm = this.formBuilder.group({
            fullname: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required]),
            profile: new FormControl('', [Validators.required])
        });
    }

    createAdminUser() {
        if (this.createAdminUserForm.valid) {
            let data_to_save = this.createAdminUserForm.value;
            data_to_save.user = this.current_user.usuario;

            this.admin_service.createAdminUser(data_to_save).subscribe((data: any) => {
                if (data && data.header && data.header.type && data.header.type == "success") {
                    // console.log(data);
                    this.toastr.typeSuccess();
                    this.activeModal.close('Ok');
                } else {

                } this.toastr.typeError();
            });

        }
    }

}
