import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PersonService } from 'app/services/person.service';
import { RouteDataService } from 'app/services/route-data.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router, private person_service: PersonService, private router_data_service: RouteDataService) { }

    canActivate(route: ActivatedRouteSnapshot): /*Observable<boolean>*/ boolean {
        console.log("Entro canActivate");
        return true;
    }
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        //console.log("AUTHGUARD", route);
        let local_token = localStorage.getItem("tkn");
        if (local_token) {
            return this.person_service.getSessionPerson().pipe(
                map((data: any) => {
                    //console.log("Data de auth", data);
                    if (data && data._id) {
                        this.router_data_service.tempData = data;
                        return true;
                    } else {
                        localStorage.removeItem("tkn");
                        this.router.navigate(['c/login']);
                        return false;
                    }
                }), catchError((err) => {
                    //console.log(err);
                    this.router.navigate(['c/login']);
                    return of(false);
                })
            );
        } else {
            this.router.navigate(['/c/login']);
            return of(false);
        }

    }
}
