import { NgModule, LOCALE_ID } from "@angular/core";
import localeGT from "@angular/common/locales/es-GT";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AWSService } from "./services/aws.services";
import { registerLocaleData } from "@angular/common";

import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";

import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { FeatherIconsModule } from "./shared/feather-icons/feather-icons.module";

import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { RouteDataService } from "./services/route-data.service";

import * as $ from "jquery";
import { UploadFileService } from "./services/upload-file.service";

import { NGXToastrService } from "./services/toastr.service";
import { GalleryComponent } from "./gallery/gallery.component";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

registerLocaleData(localeGT);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    GalleryComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    NgbModule,
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    FeatherIconsModule,
  ],
  providers: [
    RouteDataService,
    UploadFileService,
    AWSService,
    NGXToastrService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: LOCALE_ID, useValue: "es-GT" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
