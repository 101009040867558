import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { RouteDataService } from 'app/services/route-data.service';
import { PersonService } from 'app/services/person.service';
import { NGXToastrService } from 'app/services/toastr.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

current_user:any;


  formChangePassword: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private route_data_service: RouteDataService,
    private person_data:PersonService,
    private toastr: NGXToastrService) { }

  ngOnInit() {
    this.current_user = this.route_data_service.tempData;
    this.createChangePasswordForm();
  }

  //#region CHANGE PASSWORD
  
  createChangePasswordForm() {
      this.formChangePassword = this.formBuilder.group({
          user: this.current_user.usuario,
          new_ps: new FormControl('', [Validators.required]),
          confirm_new_ps: new FormControl('', [Validators.required])
      }, {
              validator: this.matchPassword
          });
  }
  matchPassword(ac: AbstractControl) {

      let password = ac.get('new_ps').value;
      let confirmPassword = ac.get('confirm_new_ps').value;
      if (password != confirmPassword) {
          //console.log("si errors.");
          ac.get('confirm_new_ps').setErrors({
              matchPassword: true
          });
      } else {
          //console.log("no errors.");
          return null;
      }
  }
  changePassword() {
      if (this.formChangePassword.valid) {
          this.person_data.modifyPassword(this.formChangePassword.value).subscribe((data: any) => {
              if (data) {
                  if (data.resultado && data.resultado.header && data.resultado.header.type === "success") {
                      this.toastr.modifyPasswordSuccess();
                      this.formChangePassword.reset();
                      this.activeModal.close('ok');
                  } else {
                      this.toastr.typeError();
                  }
              } else {
                  this.toastr.typeError();
              }
          });
      }
  }
  //#endregion
}
