import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RouteDataService {

    constructor() {
        //console.log("construyendo route-data-service");
    }
    public tempData: any;
    public tempBoard: any;
    public local_credentials: any = {
        user: "",
        psw: ""
    };

    saveLocalToken(token: string): void {
        //console.log("guardando local tokne");
        localStorage.setItem("tkn", token);
    }
    removeLocalToken(): boolean {
        if (localStorage.getItem('tkn') === null)
            return false;
        localStorage.removeItem('tkn');
        return true;
    }


}
