import { Injectable } from '@angular/core';

import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';

@Injectable()
export class UploadFileService {
    FOLDER = '';
    constructor() { }
    uploadFile(file, folder_name, file_name) {
        const bucket = new S3(
            {
                accessKeyId: 'AKIAIYT3BMXU3ADEFQFQ',
                secretAccessKey: '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',
                region: 'us-east-1'
            }
        );
        //JOnYkmI$ttt%
        const params = {
            Bucket: 'files-musicartes',
            Key: folder_name + "/" + file_name + '.' + file.name.split('.').pop(),
            Body: file,
            ACL: 'public-read'
        };

        bucket.upload(params, function (err, data) {
            if (err) {
                console.log('There was an error uploading your file: ', err);
                return false;
            }

            console.log('Successfully uploaded file.', data);
            return true;
        });
    }
}