import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AdminService } from 'app/services/admin.service';
import { NGXToastrService } from 'app/services/toastr.service';

import * as moment from 'moment';
import { RouteDataService } from 'app/services/route-data.service';
@Component({
    selector: 'app-create-artist',
    templateUrl: './create-artist.component.html',
    styleUrls: ['./create-artist.component.scss'],

})
export class CreateArtistComponent implements OnInit {

    current_user: any;

    creating_artist_result;

    createArtistForm: FormGroup;
    createDiscographyForm: FormGroup;
    discographies: any = [];
    currentYear = moment().year();
    
    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private route_data_service: RouteDataService,
        private admin_service: AdminService,
        private toastr: NGXToastrService, ) { }

    ngOnInit() {
        this.current_user = this.route_data_service.tempData;

        this.createArtistForm = this.formBuilder.group({
            artist: this.formBuilder.group({
                name: new FormControl('', [Validators.required]),
                fundation: new FormControl('', [Validators.required]),
                bio: new FormControl(),
            }),
            //   disc: this.formBuilder.array([this.createDiscography()])
        });
        this.createDiscographyForm = this.formBuilder.group({
            name: new FormControl('', [Validators.required]),
            label: new FormControl('', [Validators.required]),
            publication: new FormControl('', [Validators.required])
        });
    }
    addDiscography(): void {
        if (this.createDiscographyForm.valid) {
            this.discographies.push(this.createDiscographyForm.value);
            this.discographies = [...this.discographies];
            this.createDiscographyForm.reset();
        }
    }
    saveArtist() {
        this.creating_artist_result = "";
        if (this.createArtistForm.valid) {
            let data_to_save = this.createArtistForm.value;
            data_to_save.user = this.current_user.usuario;
            if (this.discographies.length > 0) {
                data_to_save.disc = this.discographies;
            }
            this.admin_service.saveBand(data_to_save).subscribe((data: any) => {
                console.log("Respuesta guardado de artista:", data);
                if (data && data.header && data.header.type) {
                    if (data.header.type == "success") {
                        this.toastr.dataSavedSuccess();
                        this.activeModal.close('Ok');
                    } else if (data.header.type === "warning") {
                        this.creating_artist_result = data.header.msg;
                    } else {
                        this.creating_artist_result = "Respuesta Incomprensible."
                    }
                } else {
                    this.creating_artist_result = "Respuesta Vacia."
                }
            });
        } else {
            console.log("Form no valido");
        }
    }
    removeDiscography(discography_tmp, index) {
        this.discographies.splice(index, 1);


    }
}
