import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { RouteDataService } from "app/services/route-data.service";
import { AdminService } from "app/services/admin.service";
import { NGXToastrService } from "app/services/toastr.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-create-publisher-agreement",
  templateUrl: "./create-publisher-agreement.component.html",
  styleUrls: ["./create-publisher-agreement.component.scss"],
})
export class CreatePublisherAgreementComponent implements OnInit {
  current_user: any;

  modelForm: FormGroup;

  updating: Boolean;
  existingData: any;

  participants: any;
  publishers: any;

  constructor(
    public activeModal: NgbActiveModal,
    private route_data_service: RouteDataService,
    private formBuilder: FormBuilder,
    private admin_service: AdminService,
    private toastr: NGXToastrService
  ) {}

  ngOnInit() {
    this.current_user = this.route_data_service.tempData;
    this.modelForm = this.formBuilder.group({
      participant: new FormControl(
        this.updating ? this.existingData.participant : "",
        [Validators.required]
      ),
      publisher: new FormControl(
        this.updating ? this.existingData.publisher : "",
        [Validators.required]
      ),
      begining: new FormControl(
        this.updating ? this.existingData.begining : "",
        [Validators.required]
      ),
      deadline: new FormControl(
        this.updating ? this.existingData.deadline : "",
        [Validators.required]
      ),
      agreements: [],
      // address: new FormControl(),
      // phone: new FormControl()
    });
    console.log("existing", this.existingData);
    console.log("work_participants", this.participants);
  }
  saveModel(): void {
    if (this.modelForm.valid) {
      let data_to_save = this.modelForm.value;
      data_to_save.user = this.current_user.usuario;

      data_to_save.begining =
        (data_to_save.begining.day.toString().length > 1
          ? data_to_save.begining.day
          : "0" + data_to_save.begining.day) +
        "-" +
        (data_to_save.begining.month.toString().length > 1
          ? data_to_save.begining.month
          : "0" + data_to_save.begining.month) +
        "-" +
        data_to_save.begining.year;

      data_to_save.deadline =
        (data_to_save.deadline.day.toString().length > 1
          ? data_to_save.deadline.day
          : "0" + data_to_save.deadline.day) +
        "-" +
        (data_to_save.deadline.month.toString().length > 1
          ? data_to_save.deadline.month
          : "0" + data_to_save.deadline.month) +
        "-" +
        data_to_save.deadline.year;

      this.admin_service
        .savePublisherAgreement(data_to_save)
        .subscribe((data: any) => {
          if (
            data &&
            data.response &&
            data.response.db &&
            data.response.db.typ === "success"
          ) {
            this.toastr.dataSavedSuccess();
            this.activeModal.close("Ok");
          }
        });
    }
  }
  updateModel(): void {
    if (this.modelForm.valid) {
      let data_to_save = this.modelForm.value;
      data_to_save.user = this.current_user.usuario;
      data_to_save.correlative = this.existingData.correlative;
      this.admin_service
        .updatePublisher(data_to_save)
        .subscribe((data: any) => {
          if (
            data &&
            data.response &&
            data.response.db &&
            data.response.db.typ === "success"
          ) {
            this.toastr.dataSavedSuccess();
            this.activeModal.close("Ok");
          }
        });
    }
  }
  handleParticipantChanged(): void {
    (this.modelForm.get("agreements") as FormArray).setValue([]);
    let participations = this.participants.filter(
      (item) => item.artist_code == this.modelForm.value.participant
    )[0].participations_detail;
    console.log("participations", participations);
    let ids = [];
    participations.forEach((element) => {
      ids.push({ key: element.convenio_correlativo });
      //this.modelForm.controls.agreements.push(element.convenio_correlativo);
    });
    (this.modelForm.get("agreements") as FormArray).setValue(ids);
    //(this.modelForm.get("artistas") as FormArray).setValue([]);
  }
}
