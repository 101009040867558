<div class="modal-header">
  <h4 class="modal-title text-center">
    Agregar Convenio con Editorial Musical
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('x')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- <pre>{{ modelForm.value | json }}</pre> -->
  <form class="form" [formGroup]="modelForm">
    <div class="form-body">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="">Participante</label>
            <select
              class="form-control"
              formControlName="participant"
              (change)="handleParticipantChanged()"
            >
              <option value=""></option>
              <option
                [value]="artist.artist_code"
                *ngFor="let artist of participants"
              >
                {{ artist.artist_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="">Editorial Musical</label>
            <select class="form-control" formControlName="publisher">
              <option value=""></option>
              <option
                [value]="publisher.correlative"
                *ngFor="let publisher of publishers"
              >
                {{ publisher.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="">Fecha de Inicio</label>
            <div class="input-group">
              <input
                class="form-control"
                placeholder="yyyy-mm-dd"
                ngbDatepicker
                #d="ngbDatepicker"
                formControlName="begining"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  (click)="d.toggle()"
                  type="button"
                >
                  <i-feather name="calendar"></i-feather>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="">Fecha de Vencimiento</label>
            <div class="input-group">
              <input
                class="form-control"
                placeholder="yyyy-mm-dd"
                ngbDatepicker
                #d2="ngbDatepicker"
                formControlName="deadline"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  (click)="d2.toggle()"
                  type="button"
                >
                  <i-feather name="calendar"></i-feather>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-raised btn-raised btn-primary"
    (click)="saveModel()"
    *ngIf="modelForm.valid && !updating"
  >
    Crear Convenio
  </button>
  <button
    type="button"
    class="btn btn-raised btn-raised btn-primary"
    (click)="updateModel()"
    *ngIf="modelForm.valid && updating"
  >
    Guardar
  </button>
</div>
