<div class="modal-header">
  <h4 class="modal-title text-center">Crear Evento</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('x')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- <p>{{formAppointment.value | json}}</p> -->
  <div class="row">
    <div class="col">
      <form class="form" [formGroup]='formAppointment'>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="">Asunto</label>
              <input type="text" class="form-control" formControlName="subject">
            </div>
           
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="">Usuarios</label>
              <select class="form-control" multiple="multiple" size="7" formControlName="recipients" (change)="userRecipientsChange()">
                <option value="admin">Administración</option>
                <option value="assistant">Asistencia</option>
                <option value="monitor">Monitoreo</option>
                <option value="interpreter">Socios</option>
                <option value="specific">Socio Específico</option>
                <option value="all">Todos Los Usuarios</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="specificUser">
          <div class="col">
            <div class="form-group">
              <label for="phone">Correo Electrónico</label>
              <input type="email" name="email" class="form-control" formControlName="email">
            </div>
          </div>
        </div>

      </form>
    </div>
    <div class="col">
      <form class="form" [formGroup]='formAppointment'>
        <p>Fecha</p>
        <div class="d-flex justify-content-center">
          <ngb-datepicker #dp formControlName="date"></ngb-datepicker>
        </div>
        <div class="form-group">
          <label for="phone">Horario</label>
          <select class="form-control" formControlName="time">
            <optgroup>
              <option>09:30-10:00</option>
              <option>10:30-11:00</option>
              <option>11:30-12:00</option>
              <option>12:30-13:00</option>
            </optgroup>
            <optgroup label="-------">
              <option>Todo El Dia</option>
            </optgroup>
          </select>
        </div>
      </form>
    </div>
  </div>
  <form [formGroup]="formAppointment">
    <div class="row">
      <div class="col">

        <div class="form-group">
          <label for="">Comentario</label>
          <textarea name="" id="" cols="30" rows="5" class="form-control" formControlName="comment"></textarea>
        </div>

      </div>
    </div>
  </form>
</div>

<div class="modal-footer" *ngIf="formAppointment.valid">
  <button type="button" class="btn btn-raised btn-raised btn-primary" (click)="schedule()">
    Crear Evento
  </button>
</div>
