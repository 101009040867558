<div class="modal-header">
  <h4 class="modal-title text-center">Crear Usuario</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('x')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="createAdminUserForm">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="">Nombre</label>
          <input type="text" class="form-control" formControlName="fullname">
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="">Correo</label>
          <input type="text" class="form-control" formControlName="email">
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="">Tipo Perfil</label>
          <select class="form-control" formControlName="profile">
            <option value="admin">Administrador</option>
            <option value="presidence">Presidencia</option>
            <option value="assistant">Asistencia</option>
            <option value="monitor">Monitor</option>
            <option value="audit">Auditoría</option>
          </select>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-raised btn-raised btn-primary" (click)="createAdminUser()">
    Crear Usuario Adminsitrativo
  </button>
</div>